<template>
    <v-app id="login">
        <v-container d-flex id="login_estudiante">
            <v-row>
                <v-col cols="12" class="align-center ">
                    <v-card class="elevation-3 pa-3">
                        <v-card-title class="text-center justify-center">
                            <div>
                                <v-img src="@/assets/image/LogoGris.png" contain width="350px" height="auto"></v-img>
                                <h3 class="my-4 primary--text">MIS CALIFICACIONES</h3>
                            </div>
                        </v-card-title>

                        <v-card-text>

                            <v-form ref="formUsuario" v-model="formValid" class="mt-3" @submit.prevent="">
                                <v-text-field
                                    prepend-icon="person"
                                    type="text"
                                    :label="usuarioForm.usuario.label"
                                    :placeholder="usuarioForm.usuario.placeholder"
                                    required
                                    v-model="usuarioModel.usuario"
                                    :rules="usuarioForm.usuario.rules"
                                    @keyup.enter="loginNumeroIdentidad"
                                />

                                <v-btn class="mt-3" block color="primary" ref="formUsuarioBtn"
                                       @click="loginNumeroIdentidad" :loading="loading">
                                    INGRESAR
                                </v-btn>
                            </v-form>

                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import Ls from "@/services/ls";

export default {
    name: 'LoginCalificaciones',
    data() {
        return {
            formValid: true,
            loading: false,
            usuarioModel: {
                usuario: null,
            },
            usuarioForm: {
                usuario: {
                    label: 'Número de Identidad',
                    placeholder: 'Número de Identidad',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                numero_registro: {
                    label: 'Número de Registro',
                    placeholder: 'Número de Registro',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
            },
        }
    },
    created() {
        // validar si hay session
        if (Ls.get("token")) {
            this.$router.push({name: 'Inicio'});
        }
    },
    methods: {

        loginNumeroIdentidad() {
            // Se compara por model para que permita ingresar por el otro metodo y permita iniciar sesion
            if (this.usuarioModel.usuario != null) {

                this.loading = true;

                let params = {
                    usuario: this.usuarioModel.usuario,
                    password: this.usuarioModel.usuario,
                };


                window.axios.post('/login', params).then(async response => {
                    if (response.data.status == "active") {

                        let data = response.data.data;

                        Ls.set('token', response.data.token);
                        Ls.set('tipo_persona', data.cat_tipo_persona_id);
                        Ls.set('nombre', data.nombre);
                        Ls.set('apellido', data.apellido);
                        Ls.set('rol', 2);

                        await this.$router.push({name: 'MisCalificacionesHistorial'});
                    } else if (response.data.status == "inactive") {

                        this.$toast.error(response.data.message);
                    } else if (response.data.status == "invalid") {

                        this.$toast.error(response.data.message);
                    } else if (response.data.status == "nodata") {

                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo conectar");
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            } else {
                this.$refs.formUsuario.validate();
                this.$toast.warning("Escriba el Número de Identidad.");
            }
        },
    }
}
</script>

<style src="./Login.scss" lang="scss"/>
